body {
  margin: 0;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
  font-family: "Montserrat", sans-serif;
}

/*
.card .card-header {
  background: rgba(0, 124, 0, 1);
  color: #ffffff;
}

.btn-success {
  background: rgba(0, 124, 0, 1);
  color: #ffffff;
}
*/
